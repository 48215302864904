<template>
    <div v-show="!isHidden" :style="CSS + ';height: ' + height">
    <registry-simple
            v-loading="!getRegistryRecordId() && !isEditor()"
            :element-loading-text="$locale.registry.not_available"
            element-loading-spinner="el"
            element-loading-background="rgb(244, 244, 245, 0.8)"
            :pageLimit="pageLimit"
            :showVerticalLine="showVerticalLine"
            :show-search="showSearch"
            :searchAttributes="searchAttributes"
            :showButton="showButton"
            :default-group="defaultGroup"
            :readonly="readonly"
            @open-card="openCard"
            @edit-record="editRecord"
            :mini="true"
            :headers="headers"
            ref="registry"
            :id="id"
            :outer-xref="outerXref"
            :name="name"
            :styleTableHeader="styleTableHeader"
            :treeTable="treeTable"
            :ignore-fast-card="!!defaultCardId"
    >
      </registry-simple>
        <slot></slot>
    </div>
</template>

<script>
import RegistrySimple from '@/components/Registry/RegistryTable'
import { eventBus } from '@/eventBus'
import mixin from '../mixins'
import registryMixin from './registry_mixins'
export default {
  mixins: [mixin, registryMixin],
  components: {
    RegistrySimple
  },
  props: {
    name: {
      type: String,
      frozen: true
    },
    styleTableHeader: {
      type: String,
      description: 'CSS стили шапки таблицы',
      default: () => {
        return 'font-style: normal; font-weight: normal; font-size: 13px; line-height: 20px; word-break: break-word; color: #807265'
      }
    },
    defaultGroup: {
      description: 'Группировка (attr_N_)',
      type: String
    },
    label: {
      description: 'Название',
      type: String
    },
    pageLimit: {
      type: Number,
      description: 'Количество записей на странице'
    },
    registry_properties: {
      type: Array,
      frozen: true
    },
    extendedToBottom: {
      type: Boolean,
      description: 'Растягивать вниз'
    },
    headers: {
      type: Array,
      editor: 'RegistryHeaders',
      description: 'Столбцы'
    },
    showSearch: {
      type: Boolean,
      description: 'Показывать поиск'
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    treeTable: {
      type: Boolean,
      description: 'Древовидная таблица',
      default: false
    },
    searchAttributes: {
      type: String,
      description: 'Поиск по'
    },
    filters: {
      type: Array,
      editor: 'Filters'
    },
    showButton: {
      type: Object,
      default: () => {
        return {
          update: false,
          add: false,
          delete: false,
          export: false,
          import: false,
          views: false,
          group: false,
          filter: false,
          edit: false
        }
      },
      editor: 'ShowButton',
      description: 'Видимость кнопок'
    },
    showVerticalLine: {
      type: Boolean,
      description: 'Убрать вертикальные линии'
    },
    defaultCardId: {
      type: Number,
      description: 'Карточка',
      editor: 'Cards'
    },
    defaults: {
      type: Array,
      default: () => {
        return []
      },
      editor: 'Filters',
      options: {
        title: 'По умолчанию',
        showXrefOption: false
      }
    }
  },
  data () {
    return {
      id: null,
      outerXrefId: null,
      card: null,
      multi: false,
      rendered: false,
      observer: undefined
    }
  },
  name: 'xref_outer_field',
  inject: ['getRegistryRecordId', 'openRegistryCard', 'getModel', 'isEditor', 'getRegistryId'],
  created () {
    this.parseColumnHiddenConditions(this.headers)
  },
  beforeDestroy () {
    if (this.observer) {
      this.observer.unobserve(this.$el)
    }
    eventBus.$off('registry-card-saved', this.loadData)
  },
  mounted () {
    this.$nextTick(() => {
      let me = this
      let respondToVisibility = function (element, callback) {
        let options = {
          root: document.documentElement
        }

        me.observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            callback(entry.intersectionRatio > 0)
          })
        }, options)

        me.observer.observe(element)
      }
      respondToVisibility(this.$el, (visible) => {
        if (visible) {
          if (this.$refs.registry) {
            setTimeout(() => {
              if (this.$refs.registry && this.$refs.registry.$refs.table) {
                this.$refs.registry.$refs.table.doLayout()
              }
            }, 100
            )
          }
        }
      })
      setTimeout(() => { this.rendered = true }, 100)
      if (this.$refs.registry && this.$refs.registry.$refs.table) {
        this.$refs.registry.$refs.table.doLayout()
      }
    })

    let xref = this.registry_properties.find((item) => item.id === 'xref')
    if (!xref || !xref.value || this.isEditor()) {
      return false
    }
    let me = this
    this.$http.get(`${this.$config.api}/objecteditor/entities/${xref.value}`)
      .then((response) => {
        let xrefObjectId = response.data.data.object_id
        if (response.data.data.entity_type_id === 'xref_multi_field') {
          this.multi = true
        }
        if (xrefObjectId) {
          me.id = xrefObjectId
          me.outerXrefId = xref.value
          /* me.$http.get(`${this.$config.api}/interfaceeditor/cards?entity_id=${me.id}`).then((response) => {
            let card = response.data.filter(item => item.is_default)
            me.card = card[0]
          }) */
        }
      })
    if (this.$refs.registry) {
      this.dataFilters.forEach((item) => {
        this.$refs.registry.addFilter(item)
      })
    }
    eventBus.$on('registry-card-saved', this.loadData)
  },
  watch: {
    dataFilters () {
      this.loadData()
    }
  },
  computed: {
    height () {
      if (this.extendedToBottom && !this.isHidden && this.rendered && this.$el.offsetTop) {
        return 'calc(100% - ' + this.$el.offsetTop + 'px)'
      }
      return '100%'
    },
    outerXref () {
      return {
        id: this.outerXrefId,
        value: this.getRegistryRecordId()
      }
    },
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach((item) => {
          let type = 'eq'
          if (item.isXref) {
            type = 'eqx'
          }
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              if (type === 'eqx' && parseInt(this.getModel()[item.attribute]) !== 0) {
                filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
              }
              if (this.getModel()[item.attribute] && item.alias && this.getModel()[item.attribute] + '') {
                filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
              }
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push(`${item.alias},${type},${item.attribute}`)
          }
        })
      }
      return filters
    }
  },
  methods: {
    getDefaultsForCard () {
      let defaults = []
      if (this.defaults) {
        this.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    loadData () {
      if (!this.$refs.registry || this.isEditor()) {
        return false
      }
      this.$refs.registry.clearFilters()
      this.dataFilters.forEach((item) => {
        this.$refs.registry.addFilter(item)
      })
      this.$refs.registry.loadData()
    },
    parseColumnHiddenConditions (columns) {
      columns.forEach((column) => {
        if ((column.hidden_condition || '').trim().length > 0) {
          let condition = JSON.parse(JSON.stringify(column.hidden_condition))
          let attributes = condition.match(/\{(.*?)\}/g)
          attributes.forEach((attribute) => {
            attribute = attribute.replace('{', '').replace('}', '')
            condition = condition.replace(`{${attribute}}`, this.getModel()[attribute])
          })
          try {
            if (eval(condition)) {
              column.hidden = true
            }
          } catch (e) {
            console.info(`invalid condition: ${column.hidden_condition}, result: ${condition}`)
          }
        }
        if ((column.children || []).length > 0) {
          this.parseColumnHiddenConditions(column.children)
        }
        if ((column.children || []).length > 0 && (column.children || []).filter(item => item.hidden).length === (column.children || []).length) {
          column.hidden = true
        }
      })
    },
    async getCardId (recordId) {
      let url = `${this.$config.api}/registryservice/registry/${this.id}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${this.id}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)

      return data.data[0]
    },
    async editRecord (data) {
      let card = await this.getCardId(data.id)
      let initialData = {}
      if (this.getRegistryId() !== this.id) {
        initialData = JSON.parse(JSON.stringify(this.getModel()))
      }
      this.openRegistryCard({
        registryId: this.id,
        cardId: card.id,
        cardName: card.name,
        recordId: data.id,
        initialData: initialData,
        registry: this.$refs.registry
      })
    },
    async openCard () {
      if (!this.getRegistryRecordId()) {
        return false
      }
      let card = {}
      if (this.defaultCardId) {
        card = {
          id: this.defaultCardId
        }
      } else {
        card = await this.getCardId()
      }
      if (!card) {
        return false
      }
      let initialData = {}
      if (this.getRegistryId() !== this.id) {
        initialData = JSON.parse(JSON.stringify(this.getModel()))
      }
      initialData[`attr_${this.outerXrefId}_`] = this.multi ? JSON.stringify([{ id: this.getRegistryRecordId() }]) : this.getRegistryRecordId()
      let defaults = this.getDefaultsForCard()
      defaults.forEach((item) => {
        initialData[item.key] = item.value
      })
      this.openRegistryCard({
        registryId: this.id,
        cardId: card.id,
        cardName: card.name,
        recordId: null,
        initialData: initialData,
        registry: this.$refs.registry
      })
    }
  }
}
</script>

<style scoped>

</style>
